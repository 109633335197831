<script lang="ts" setup>
defineProps<{ disabled?: boolean }>()

const open = defineModel<boolean>()

function handleToggle(e: ToggleEvent) {
  if (!e.target)
    return

  open.value = (e.target as HTMLDetailsElement).open
}
</script>

<template>
  <details
    :open="open"
    :class="{ 'pointer-events-none text-gray-500': disabled }"
    @toggle="handleToggle"
  >
    <summary
      class="flex cursor-pointer select-none items-center"
    >
      <slot name="summary" />
      <UIcon
        name="solid:caret-right"
        class="ml-2 text-sm text-gray-600 transition-transform duration-200 dark:text-gray-400"
        :class="{ 'rotate-90': open }"
      />
    </summary>
    <slot />
  </details>
</template>
